import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

/**
 * Regular image structure
 */
type RegularImage = {
  image: string
  className?: string
  alt: string
}

/**
 * Sharp image structure
 */
type GatsbyImage = {
  image: IGatsbyImageData
  className?: string
  imgClassName?: string
  alt: string
}

// Union check
type ImageType = RegularImage | GatsbyImage

// Chekc if image prop is set and if it's a string
const isRegularImage = (props: ImageType): props is RegularImage => {
  return 'image' in props && typeof props?.image === 'string'
}

const Image = (props: ImageType) => {
  if (isRegularImage(props)) {
    return <img className={props.className + ' object-cover'} src={props.image} alt={props.alt} />
  } else {
    return (
      <GatsbyImage imgClassName={props.imgClassName} className={props.className} image={props.image} alt={props.alt} />
    )
  }
}
export default Image
