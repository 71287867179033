import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

interface CaptionBoxData {
  caption: any
  isVisible: boolean
}

const CaptionBox = ({ caption, isVisible }: CaptionBoxData) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ y: 400, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 400, opacity: 0 }}
          className="bg-whitefade p-4 text-sm inline-block max-w-[75%] lg:max-w-[50%] caption-text"
        >
          <div dangerouslySetInnerHTML={{ __html: caption }} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default CaptionBox
