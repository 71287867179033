import React from 'react'
import Footer from '@/components/Footer'
import Hero from '@/components/Hero'
import Nav from '@/components/Nav'
import SocialMedia from '../SocialMedia'
import Container from '@/components/Container'
import Seo from '@/components/Seo'

export interface MainLayoutProps {
  /** Page content */
  children: React.ReactNode
  featuredImage?: any
  nav?: any
  lang?: any
  form?: any
  showhero?: boolean
  instagram?: any
  isFrontpage?: boolean
  cloudLeft?: boolean
  cloudRight?: boolean
  uri?: string
  seotitle?: string
  seodesc?: string
  seokeywors?: any
}

/** Component shares layout structure between pages. Pass common sections like header, footer and content container here and wrap page components with it */
const MainLayout = ({
  children,
  featuredImage,
  nav,
  lang,
  form,
  instagram,
  showhero = true,
  isFrontpage = false,
  cloudLeft = false,
  cloudRight = false,
  uri,
  seotitle,
  seodesc,
  seokeywors,
}: MainLayoutProps) => {
  return (
    <>
      <Seo
        title={seotitle}
        description={seodesc}
        keywords={seokeywors}
        pathname={uri}
        lang={lang?.currentLang}
        image={featuredImage?.node?.localFile?.url}
      />
      <Nav nav={nav} lang={lang} showhero={showhero} />
      {showhero ? (
        <Hero heroImage={featuredImage} isFrontpage={isFrontpage} cloudLeft={cloudLeft} cloudRight={cloudRight} />
      ) : (
        <div className="h-[0px]"></div>
      )}
      <main id="main" className="w-full main-height">
        {children}
      </main>
      <Container>
        <SocialMedia instagram={instagram} />
      </Container>
      <Footer form={form} lang={lang} />
    </>
  )
}

MainLayout.defaultProps = {
  location: '/',
  lang: 'fi',
}

export default MainLayout
