import React, { useRef, useEffect } from 'react'
import DefaultLink from '@/components/DefaultLink'

interface NavData {
  id: string
  label: string
  parentId: string
  url: string
}
interface ChildMenuData {
  childItems: Array<NavData>
  onClickOutside: any
  parent: any
  eWidth: any
}

const ChildMenu = ({ childItems, onClickOutside, parent, eWidth }: ChildMenuData) => {
  const childWindow: any = useRef(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (childWindow.current && !childWindow.current.contains(event.target) && event.target != parent) {
      onClickOutside && onClickOutside()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return (
    <div style={{ width: eWidth }} ref={childWindow} className="text-center mt-2 ml-6 mb-2">
      <ul>
        {childItems.map((child, index: number) => {
          return (
            <li key={index} className="leading-6">
              <DefaultLink
                key={child.id}
                className="text-gray text-tiny leading-4 tracking-widest px-2 py-1 font-bold focus:text-hovbrown border-b-2 border-primary hover:border-white focus:border-white"
                to={child.url}
              >
                {child.label}
              </DefaultLink>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ChildMenu
