import React from 'react'

const Linkedin = () => {
  return (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
      <g>
        <g>
          <g>
            <circle className="st0" cx="186.2" cy="186.2" r="23.3" />
            <rect x="162.9" y="221.1" className="st0" width="46.5" height="128" />
            <path
              className="st0"
              d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M256,488.7
                        C127.5,488.7,23.3,384.5,23.3,256S127.5,23.3,256,23.3S488.7,127.5,488.7,256S384.5,488.7,256,488.7z"
            />
            <path
              className="st0"
              d="M318.4,221.1c-34.9,0-39.1,20.7-39.1,20.7v-20.7h-46.5v128h46.5v-69.8c0,0,0-23.3,19.8-23.3
                        c11.6,0,15.1,10.4,15.1,23.3v69.8h46.5v-69.8C360.7,242.9,344.9,221.1,318.4,221.1z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
export default Linkedin
