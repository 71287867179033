import React from 'react'

export interface ContainerProps {
  children: React.ReactNode
  className?: string
  classNameInner?: string
  defaultWidth?: boolean
}

const Container = ({ children, className = '', classNameInner = '', defaultWidth = true }: ContainerProps) => {
  return (
    <div className={`w-full ${className}`}>
      <div className={`${defaultWidth && 'max-w-content'} w-full mx-auto px-5 sm:px-10 2xl:px-2 ${classNameInner}`}>
        {children}
      </div>
    </div>
  )
}

export default Container
