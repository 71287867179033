import React from 'react'
import Container from '@/components/Container'
import DefaultLink from '@/components/DefaultLink'
import MobileNavItem from '@/components/Nav/MobileNavItem'
import RoundedLink from '@/components/RoundedLink'

interface Navigation {
  id: string
  label: string
  parentId: string
  url: string
}
interface MobileNavData {
  nav?: {
    nodes?: Array<Navigation>
  }
  open?: boolean
  width?: any
  subscribrButton: any
  giftcardButton: any
}

const MobileNav = ({ nav, open, width, subscribrButton, giftcardButton }: MobileNavData) => {
  if (width > 1023) {
    return null
  }

  return (
    <div
      className={`w-screen transition-height shadow-xl duration-700 top-30 overflow-y-scroll ${
        open ? 'h-[420px]' : 'h-0'
      }`}
    >
      <Container>
        <nav
          className={`transition-opacity duration-1000 uppercase text-center mt-2 mb-1 ${
            open ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <ul className="mb-4">
            {nav?.nodes?.map((item: any) => {
              if (item.parentId === null) {
                if (item.childItems.nodes.length > 0) {
                  return <MobileNavItem key={item.id} navItem={item} />
                } else {
                  return (
                    <li key={item.id}>
                      <DefaultLink
                        className="text-white uppercase font-bold py-2 inline-block tracking-widest border-b-2 border-primary hover:border-white focus:border-white"
                        to={item.url}
                      >
                        {item.label}
                      </DefaultLink>
                    </li>
                  )
                }
              } else {
                return null
              }
            })}
            {subscribrButton?.url && (
              <li className="my-1 text-center">
                <RoundedLink
                  invert={true}
                  to={subscribrButton?.url}
                  targetBlank={subscribrButton?.blank}
                  className="mt-1"
                >
                  {subscribrButton?.anchor}
                </RoundedLink>
              </li>
            )}
            {giftcardButton?.url && (
              <li className="my-1 text-center">
                <RoundedLink
                  invert={true}
                  to={giftcardButton?.url}
                  targetBlank={giftcardButton?.blank}
                  className="mt-1"
                >
                  {giftcardButton?.anchor}
                </RoundedLink>
              </li>
            )}
          </ul>
        </nav>
      </Container>
    </div>
  )
}

export default MobileNav
