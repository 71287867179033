import React from 'react'
import Container from '@/components/Container'
import DefaultLink from '@/components/DefaultLink'
import Section from '@/components/Footer/Section'
import FooterLogo from '@/assets/FooterLogo'
import { graphql, useStaticQuery } from 'gatsby'
import FooterClouds from '@/assets/FooterClouds'
import GravityForm from '@/components/GravityForm'
import RoundedLink from '@/components/RoundedLink'
import Image from '@/components/Image'
import { getImage } from 'gatsby-plugin-image'

interface Languages {
  slug: string
  code: string
  name: string
}
interface Translations {
  uri: string
  language: {
    slug: string
  }
  slug: string
}
interface FooterData {
  form?: any
  lang: {
    currentLang: string
    languages: Array<Languages>
    translations: Array<Translations>
  }
}

const Footer = ({ lang, form }: FooterData) => {
  const { currentLang } = lang

  const { footerFi, footerEn, footernav, footernavEn } = useStaticQuery(footerQuery)

  const { footer } = currentLang == 'fi' ? footerFi : currentLang == 'en' ? footerEn : footerFi
  const { menuItems } = currentLang == 'fi' ? footernav : currentLang == 'en' ? footernavEn : footernav

  return (
    <>
      <div className="w-full text-primary">
        <FooterClouds />
      </div>
      <footer className="bg-primary text-white tracking-full text-base">
        <Container className="py-20">
          <div className="grid sm:grid-cols-2 lg:grid-cols-11 lg:gap-4 sm:gap-10 gap-20">
            <Section outerClass="lg:col-span-3">
              <div className="footer-logo max-w-[300px]">
                <FooterLogo />
              </div>
              <p className="font-bold mt-6 lg:ml-16 leading-7">{footer?.slogan}</p>
            </Section>
            <Section
              outerClass="lg:col-span-3"
              heading={footer?.contactHeading}
              headingClass="lg:mx-6"
              className="lg:mx-6"
            >
              <div className="break-words text-normal footer-contact" dangerouslySetInnerHTML={{ __html: footer?.contact }} />
            </Section>
            <Section
              outerClass="lg:col-span-3"
              heading={footer?.formHeading}
              className="lg:mx-6"
              headingClass="font-normal lg:mx-6"
            >
              {form && <GravityForm invertColors={false} form={form} />}
            </Section>
            <Section outerClass="lg:col-span-2" headingClass="font-normal lg:ml-5">
              {menuItems?.nodes?.map((item: any, index: number) => {
                return (
                  <RoundedLink
                    paddingX={false}
                    key={item.id + index}
                    className="w-full mb-2 break-words text-center px-2"
                    invert={true}
                    to={item.url}
                  >
                    {item.label}
                  </RoundedLink>
                )
              })}

              {footer?.footerImgs && (
                <div className="flex mt-6">
                  {footer?.footerImgs?.map((item: any, index: number) => {
                    const image: any = getImage(item?.image?.localFile) || null
                    return (
                      <div key={index} className="w-1/3 m-1">
                        {item.url ? (
                          <DefaultLink to={item?.url}>
                            {image && (
                              <Image
                                image={image}
                                className={'h-auto relative w-full'}
                                alt={item?.image?.altText ? item?.image?.altText : 'Kuva / Image'}
                              />
                            )}
                          </DefaultLink>
                        ) : (
                          <div>
                            {image && (
                              <Image
                                image={image}
                                className={'h-auto relative w-full'}
                                alt={item?.image?.altText ? item?.image?.altText : 'Kuva / Image'}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )}
            </Section>
          </div>
        </Container>
        <div className="w-full border-t border-darkgray flex justify-center items-center text-darkgray px-2 py-4 uppercase text-center opacity-50">
          <DefaultLink className="hover:text-yellow focus:text-yellow tracking-full text-sm" to="https://g.works/">
            Design ja toteutus G-works
          </DefaultLink>
        </div>
      </footer>
    </>
  )
}

const footerQuery = graphql`
  query FooterQuery {
    footerFi: wp {
      footer: crbThemeOptions {
        slogan: gwFooterSloganCrblangFi
        contact: gwFooterContactCrblangFi
        contactHeading: gwFooterContactHeadingCrblangFi
        formHeading: gwFooterFormHeadingCrblangFi
        footerImgs: gwFooterImages {
          url
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 70)
              }
            }
          }
        }
      }
    }
    footerEn: wp {
      footer: crbThemeOptions {
        contact: gwFooterContactCrblangEn
        slogan: gwFooterSloganCrblangEn
        contactHeading: gwFooterContactHeadingCrblangEn
        formHeading: gwFooterFormHeadingCrblangEn
        footerImgs: gwFooterImages {
          url
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 70)
              }
            }
          }
        }
      }
    }
    footernav: wpMenu(locations: { eq: FOOTER_MENU }) {
      ...navigationFooter
    }
    footernavEn: wpMenu(locations: { eq: FOOTER_MENU___EN }) {
      ...navigationFooter
    }
  }
`

export default Footer
