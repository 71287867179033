import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import MintCloud from '@/assets/MintCloud'
import Container from '@/components/Container'
import InfoBg from '@/assets/InfoBg'
import CaptionBox from '@/components/CaptionBox'

interface HeroData {
  heroImage?: any
  isFrontpage?: boolean
  cloudLeft?: boolean
  cloudRight?: boolean
}

const Hero = ({ heroImage, isFrontpage = false, cloudLeft = false, cloudRight = false }: HeroData) => {
  const image: any = getImage(heroImage?.node?.localFile)
  const altText: string = heroImage?.node?.altText || 'featured image'

  const [showCaption, setShowCaption] = useState(false)

  if (typeof heroImage === 'object') {
    return (
      <div className="relative">
        <div
          className={`Hero mb-10 w-full relative overflow-hidden ${
            isFrontpage ? 'h-[300px] sm:h-[400px] lg:h-[600px]' : 'h-[280px] sm:h-[340px] lg:h-[470px]'
          }`}
        >
          {image && (
            <GatsbyImage
              className="h-full w-full object-cover contrast-150 grayscale brightness-75"
              image={image}
              alt={altText}
            />
          )}
          <div className="absolute h-full w-full bg-filterprimary top-0 left-0 opacity-50"></div>
          {heroImage?.node?.caption && (
            <Container className="absolute bottom-10 hidden sm:block">
              <div className={`flex mb-4 ${cloudLeft ? 'justify-end' : ''}`}>
                <button
                  className={`info-logo transition-all ${showCaption ? 'info-active' : ''}`}
                  onClick={() => setShowCaption(showCaption ? false : true)}
                >
                  <InfoBg />
                </button>
              </div>
              <div className={`flex ${cloudLeft ? 'justify-end' : ''}`}>
                <CaptionBox caption={heroImage?.node?.caption} isVisible={showCaption} />
              </div>
            </Container>
          )}
        </div>
        {cloudLeft && (
          <div className="hidden sm:block cloud-mint absolute bottom-[-50px] lg:bottom-[-250px] sm:left-[-120px] md:left-[-120px] lg:left-[-370px] xl:left-[-250px] 3xl:left-[-190px] w-[200px] lg:w-[480px]">
            <MintCloud />
          </div>
        )}
        {cloudRight && (
          <div className="hidden sm:block cloud-mint absolute bottom-[-100px] lg:bottom-[-250px] sm:right-[-100px] md:right-[-100px] lg:right-[-330px] xl:right-[-220px] 3xl:right-[-160px] w-[200px] lg:w-[460px]">
            <MintCloud />
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div>
        <div className="Hero mb-20"></div>
      </div>
    )
  }
}

export default Hero
