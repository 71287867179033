import React from 'react'

const Facebook = () => {
  return (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M232.7,197.8v23.3h-23.3V256h23.3v104.7h46.5V256h31l4-34.9h-34.9v-20.4c0-9.4,0.9-14.4,15.5-14.4h19.4v-35
              H283C245.8,151.3,232.7,168.7,232.7,197.8z"
            />
            <path
              className="st0"
              d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M256,488.7
              C127.5,488.7,23.3,384.5,23.3,256S127.5,23.3,256,23.3S488.7,127.5,488.7,256S384.5,488.7,256,488.7z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
export default Facebook
