import React from 'react'

const HeaderClouds = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1400 114.4">
      <path
        className="st0"
        d="M0,0v41.5C63.3,75.9,133.5,84,211.8,65.6C268,52.3,322.5,30.4,376.4,8.9c30.8-12.3,52.8-8.9,75.1,15.8
        c28.8,31.7,63.8,55.6,105.3,68.5c133.8,41.7,258.4,22.4,370.9-60.9c34.5-25.5,57.4-29.5,95.7-1.5C1131,109.4,1299.2,88.6,1399.9,0H0
        z"
      />
    </svg>
  )
}
export default HeaderClouds
