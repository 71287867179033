import * as React from 'react'

import { GravityFormsForm as GravityFormsFormType } from '../../../generated/graphql'
import { GravityFormProvider } from '../../hooks/useGravityForm'
import GravityFormsForm from './GravityFormsForm'

interface Props {
  form: GravityFormsFormType
  invertColors?: boolean
}

export default function GravityForm(props: Props) {
  return (
    <GravityFormProvider>
      <GravityFormsForm invertColors={props.invertColors} {...props} />
    </GravityFormProvider>
  )
}
