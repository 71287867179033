import * as React from 'react'
import { graphql } from 'gatsby'

import { AddressField as AddressFieldType, AddressInput, FieldError } from '../../../../generated/graphql'
import useGravityForm, { ACTION_TYPES, FieldValue, AddressFieldValue } from '../../../hooks/useGravityForm'

export const ADDRESS_FIELD_FIELDS = graphql`
  fragment AddressFieldFields on WpAddressField {
    id
    formId
    label
    description
    cssClass
    inputs {
      key
      label
      placeholder
    }
  }
`

interface Props {
  field: AddressFieldType
  fieldErrors: FieldError[]
  invertColors?: boolean
}

const DEFAULT_VALUE: AddressInput = {}

const AUTOCOMPLETE_ATTRIBUTES: { [key: string]: string } = {
  street: 'address-line1',
  lineTwo: 'address-line2',
  city: 'address-level2',
  state: 'address-level1',
  country: 'country-name',
}

export default function AddressField({ field, fieldErrors, invertColors = false }: Props) {
  const { id, formId, type, label, description, inputs } = field
  const htmlId = `field_${formId}_${id}`
  const { state, dispatch } = useGravityForm()
  const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as AddressFieldValue | undefined
  const addressValues = fieldValue?.addressValues || DEFAULT_VALUE
  const colorsField = invertColors ? 'bg-white' : 'bg-primary'

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    const newAddressValues = { ...addressValues, [name]: value }

    dispatch({
      type: ACTION_TYPES.updateAddressFieldValue,
      fieldValue: {
        id,
        addressValues: newAddressValues,
      },
    })
  }

  return (
    <fieldset id={htmlId} className={`mb-4 gfield gfield-${type}`.trim()}>
      <legend>{label}</legend>
      {inputs?.map(input => {
        const key = input?.key as keyof AddressInput
        const inputLabel = input?.label || ''
        const placeholder = input?.placeholder || ''
        return (
          <div key={key}>
            <input
              className={`${colorsField} shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
              type="text"
              name={String(key)}
              id={`input_${formId}_${id}_${key}`}
              placeholder={placeholder}
              autoComplete={AUTOCOMPLETE_ATTRIBUTES[key]}
              value={addressValues?.[key] ?? ''}
              onChange={handleChange}
            />
            <label className="block text-gray-700 uppercase font-bold mb-2" htmlFor={`input_${formId}_${id}_${key}`}>
              {inputLabel}
            </label>
          </div>
        )
      })}
      {description ? <p className="field-description">{description}</p> : null}
      {fieldErrors?.length
        ? fieldErrors.map(fieldError => (
            <p key={fieldError.id} className="error-message">
              {fieldError.message}
            </p>
          ))
        : null}
    </fieldset>
  )
}
