import React from 'react'

interface SectionProps {
  children?: React.ReactNode
  heading?: string
  headingClass?: string
  className?: string
  outerClass?: string
  rawContent?: any
}

const Section = ({ children, heading, headingClass, className = '', outerClass = '' }: SectionProps) => {
  return (
    <section className={`flex flex-col h-100 wordbreak footer-section ${outerClass}`}>
      {heading && <h2 className={`uppercase text-normal mb-6 ${headingClass}`}>{heading}</h2>}
      <div className={className}>{children}</div>
    </section>
  )
}

export default Section
