import * as React from 'react'
import { graphql } from 'gatsby'
import Select from 'react-select'

import { MultiSelectField as MultiSelectFieldType, FieldError } from '../../../../generated/graphql'
import useGravityForm, { ACTION_TYPES, FieldValue, StringFieldValues } from '../../../hooks/useGravityForm'

export const MULTI_SELECT_FIELD_FIELDS = graphql`
  fragment MultiSelectFieldFields on WpMultiSelectField {
    id
    formId
    label
    description
    cssClass
    isRequired
    choices {
      text
      value
    }
  }
`

interface Props {
  field: MultiSelectFieldType
  fieldErrors: FieldError[]
  invertColors?: boolean
}

interface Option {
  value: string
  label: string
}

const DEFAULT_VALUE: string[] = []

export default function MultiSelectField({ field, fieldErrors, invertColors = false }: Props) {
  const { id, formId, type, label, description, cssClass, isRequired, choices } = field
  const htmlId = `field_${formId}_${id}`
  const { state, dispatch } = useGravityForm()
  const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as StringFieldValues | undefined
  const values = fieldValue?.values || DEFAULT_VALUE
  const options = choices?.map(choice => ({ value: choice?.value, label: choice?.text })) || []
  const selectedOptions = options.filter(option => values.includes(String(option?.value)))

  function handleChange(value: any) {
    const values = value.map((option: Option) => option.value)
    dispatch({
      type: ACTION_TYPES.updateMultiSelectFieldValue,
      fieldValue: { id, values },
    })
  }
  const colors = invertColors ? 'border-primary text-black' : 'border-white text-white'
  const colorsField = invertColors ? 'bg-white' : 'bg-primary'

  return (
    <div
      className={`${colors} border-l-2 border-b-2 rounded-bl-2xl pl-2 mb-4 pb-4 gfield gfield-${type} ${cssClass}`.trim()}
    >
      <label htmlFor={htmlId}>{label}</label>
      <Select
        isMulti
        name={String(id)}
        inputId={htmlId}
        required={Boolean(isRequired)}
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        className={'text-primary'}
      />
      {description ? <p className="field-description">{description}</p> : null}
      {fieldErrors?.length
        ? fieldErrors.map(fieldError => (
            <p key={fieldError.id} className="error-message">
              {fieldError.message}
            </p>
          ))
        : null}
    </div>
  )
}
