import React from 'react'

const MintCloud = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1053.6 992.2">
      <path
        className="st0"
        d="M679.1,992.2c-27.7,0-55.4,0-83.1,0c-51.8-10.7-97.8-32.8-140-65.3c-37.7-29.1-80.4-23.3-120.9-5.3
            c-56.2,25-113.2,39.2-172.2,13.8C96.8,907,54.4,856.5,32.4,789.8c-3.8-11.6-5.4-23.8-4.1-36c2-18.9,9.6-31.7,14.3-49.2
            c5.9-22.2,4.8-41.3-6.5-61C-48.8,495.4,8.6,263,170,198.1c4.9-2,21.5-8.6,44-16.3C321.8,155,324.5,132.4,374,94.5
            C493.5,3.2,759.9-4.3,862.6,192.1c39.1,74.7,49.2,155.3,35.9,238.4c-3.1,19.5-2.7,33.3,15,46.8c116.1,88.5,111,270.5,10.8,363.4
            c-28.2,26.2-55.6,52.1-91,68.7c-12.3,5.7-24.1,14.1-33.6,23.8C766.3,967.4,725.5,985.2,679.1,992.2z"
      />
      <path
        className="st1"
        d="M642.4,970.4l-1-0.2c-52-10.8-98.6-32.5-142.7-66.6c-29.2-22.6-65.1-23.9-109.7-4
            c-33.2,14.8-60.8,23.1-87.1,26.3c-32.6,4-62.5-0.2-91.5-12.6C144.5,884.9,99.2,834.4,75.7,763c-4.4-13.3-5.9-26.6-4.5-39.8
            c1.4-12.9,5.1-23.1,8.7-33C82,684.4,84,679,85.6,673c5.5-20.9,3.9-36.7-5.5-53c-22.6-39.5-36.2-86.7-39.3-136.5
            c-3-48.1,3.8-97.5,19.6-143.1c14-40.2,34.1-75.7,59.9-105.4c27.6-31.9,60.4-55.5,97.4-70.4c14.1-5.7,28.9-11.2,44.1-16.4l0.8-0.2
            c80.1-19.8,99.4-37,126.2-60.7c8-7.1,17.2-15.2,28.6-23.9C489.7,8,607-13.8,709.4,8.9c43.8,9.7,83.3,26.9,117.3,51.1
            c37.6,26.7,67.8,61.4,89.6,103.2c38.3,73.1,50.6,154.7,36.5,242.3c-3,18.7-1.8,27.1,11.1,36.9c27.7,21.1,49.6,47.9,65,79.5
            c14.5,29.7,22.7,62.2,24.3,96.7c3.6,75.9-25.5,150.3-77.9,198.9l-2.9,2.7c-26.8,24.9-54.6,50.6-89.9,67.1
            c-11.1,5.2-21.9,12.8-30.3,21.6c-32.7,33.6-73.6,53.7-125.1,61.5l-0.7,0.1L642.4,970.4z M446.2,865.7c23.8,0,45.5,7.5,64.6,22.2
            c41.4,32,85.1,52.5,133.6,62.7l80.6,0c46.7-7.1,83.7-25.3,113.1-55.6c10.1-10.4,22.9-19.5,36.2-25.7c32.4-15.2,57.9-38.7,84.8-63.7
            l2.9-2.7c48.2-44.6,75-113.2,71.6-183.4c-1.5-31.8-9-61.7-22.3-89c-14.1-28.9-34-53.2-59.2-72.5c-10-7.7-16.2-16.3-18.7-26.4
            c-2.5-9.7-1.4-19.9,0.1-29.4c13.3-83.3,1.7-160.7-34.5-230c-20.4-39-48.5-71.4-83.6-96.3c-31.9-22.6-68.9-38.7-110.1-47.9
            c-48.7-10.8-101.2-11.3-151.9-1.4c-48,9.3-91.9,27.9-123.7,52.2c-10.9,8.3-19.3,15.8-27.5,23c-27.8,24.6-49.7,44-134.2,65
            c-14.7,5-29.1,10.4-42.7,15.9c-34.1,13.7-64.3,35.6-89.8,65c-24.1,27.9-43,61.2-56.2,99c-15,43.1-21.4,89.9-18.6,135.4
            c2.9,46.8,15.6,91,36.7,127.8c11.8,20.7,14.2,42.3,7.4,67.9c-1.8,6.9-4.1,13-6.2,19c-3.4,9.2-6.5,17.8-7.6,28.3
            c-1.1,10.3,0.1,20.9,3.6,31.5c21.7,65.8,63.3,112.3,123.8,138.3c25.7,11,52.3,14.7,81.2,11.2c24.2-3,50.1-10.8,81.4-24.8
            c23.7-10.5,44.9-15.7,64.9-15.8C446,865.7,446.1,865.7,446.2,865.7z"
      />
    </svg>
  )
}
export default MintCloud
