import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

interface Data {
  children: React.ReactNode
  to: string
  className?: string
  activeClassName?: string
  partiallyActive?: boolean
  invert?: boolean
  paddingX?: boolean
  targetBlank?: any
}

const RoundedLink = ({ children, to, className = '', invert = false, paddingX = true, targetBlank = '0' }: Data) => {
  const href = to

  const url = '/' + to.replace(/^(?:\/\/|[^/]+)*\//, '')

  const isWpContent = href.includes('/wp-content/')

  const defaultStyle =
    'border-primary font-bold bg-primary text-white hover:text-primary focus:text-primary hover:bg-white focus:bg-white'
  const invertStyle =
    'border-white font-bold bg-white text-primary hover:text-white focus:text-white hover:bg-primary focus:bg-primary'

  if (targetBlank == '1' || targetBlank == 1) {
    return (
      <a
        target="_blank"
        href={href}
        className={`${className} ${
          paddingX && 'px-6'
        } leading-6 tracking-full pt-2 pb-1 inline-block border-2 rounded-3xl uppercase text-center ${
          invert ? invertStyle : defaultStyle
        }`}
        rel="noreferrer"
      >
        {children}
      </a>
    )
  } else {
    return (
      <>
        {isWpContent ? (
          <a
            href={href}
            className={`${className} ${
              paddingX && 'px-6'
            } leading-6 tracking-full pt-2 pb-1 inline-block border-2 rounded-3xl uppercase text-center ${
              invert ? invertStyle : defaultStyle
            }`}
          >
            {children}
          </a>
        ) : (
          <AniLink
            paintDrip
            to={url}
            hex="#69DBC8"
            className={`${className} ${
              paddingX && 'px-6'
            } leading-6 tracking-full pt-2 pb-1 inline-block border-2 rounded-3xl uppercase text-center ${
              invert ? invertStyle : defaultStyle
            }`}
          >
            {children}
          </AniLink>
        )}
      </>
    )
  }
}
export default RoundedLink
