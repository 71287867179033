import React from 'react'

const Instagram = () => {
  return (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
      <g>
        <ellipse
          transform="matrix(0.3827 -0.9239 0.9239 0.3827 24.6228 413.6469)"
          className="st0"
          cx="321.8"
          cy="188.4"
          rx="15"
          ry="15"
        />
        <path
          className="st0"
          d="M379.4,203.6c-0.6-13.4-2.7-22.4-5.8-30.4c-3.2-8.3-8.1-15.9-14.5-22.1c-6.2-6.4-13.8-11.3-22.1-14.5
          c-8-3.1-17.1-5.2-30.4-5.8c-13.4-0.6-17.6-0.8-51.6-0.8s-38.2,0.2-51.6,0.8s-22.4,2.7-30.4,5.8c-8.3,3.2-15.9,8.1-22.1,14.5
          c-6.4,6.2-11.3,13.8-14.5,22.1c-3.1,8-5.2,17.1-5.8,30.4c-0.6,13.4-0.8,17.6-0.8,51.6s0.2,38.2,0.8,51.6
          c0.6,13.4,2.7,22.5,5.8,30.4c3.2,8.3,8.1,15.9,14.5,22.1c6.2,6.4,13.8,11.3,22.1,14.5c8,3.1,17.1,5.2,30.4,5.8
          c13.4,0.6,17.6,0.8,51.6,0.8s38.2-0.2,51.6-0.8c13.4-0.6,22.5-2.7,30.4-5.8c16.9-6.5,30.1-19.8,36.6-36.6c3.1-8,5.2-17.1,5.8-30.4
          c0.6-13.4,0.8-17.6,0.8-51.6S380,216.9,379.4,203.6z M356.9,305.7c-0.6,12.2-2.6,18.8-4.3,23.2c-4.2,10.9-12.9,19.6-23.8,23.8
          c-4.4,1.8-11.1,3.7-23.2,4.3c-13.2,0.7-17.1,0.8-50.6,0.8c-33.5,0-37.4-0.2-50.6-0.8c-12.2-0.6-18.8-2.6-23.2-4.3
          c-5.4-2-10.4-5.2-14.4-9.4c-4.2-4-7.3-8.9-9.4-14.4c-1.8-4.4-3.7-11.1-4.3-23.2c-0.6-13.2-0.8-17.2-0.8-50.6
          c0-33.5,0.2-37.4,0.8-50.6c0.6-12.2,2.6-18.8,4.3-23.2c2-5.4,5.2-10.4,9.4-14.4c4-4.2,8.9-7.3,14.4-9.4c4.4-1.7,11.1-3.7,23.2-4.3
          c13.2-0.7,17.1-0.8,50.6-0.8c33.4,0,37.4,0.2,50.6,0.8c12.2,0.6,18.8,2.6,23.2,4.3c5.4,2,10.4,5.2,14.4,9.4c4.2,4,7.3,8.9,9.4,14.4
          c1.7,4.4,3.7,11.1,4.3,23.2c0.7,13.2,0.8,17.1,0.8,50.6C357.7,288.5,357.5,292.5,356.9,305.7z"
        />
      </g>
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M256,488.7
              C127.5,488.7,23.3,384.5,23.3,256S127.5,23.3,256,23.3S488.7,127.5,488.7,256S384.5,488.7,256,488.7z"
            />
          </g>
        </g>
      </g>
      <path
        className="st0"
        d="M255,190.9c-35.5,0-64.3,28.8-64.3,64.3c0,35.5,28.8,64.3,64.3,64.3c35.5,0,64.3-28.8,64.3-64.3
        C319.3,219.6,290.5,190.9,255,190.9z M255,296.9c-23.1,0-41.7-18.6-41.7-41.7c0-23.1,18.6-41.7,41.7-41.7s41.7,18.6,41.7,41.7
        C296.7,278.2,278.1,296.9,255,296.9z"
      />
    </svg>
  )
}
export default Instagram
