import React from 'react'

const LogoMobile = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000">
      <g>
        <path
          className="st0"
          d="M840.1,261.7c-2.3-70.7-31.5-136.6-82.4-185.7C707,27,640.3,0,569.9,0c-3,0-5.9,0-8.9,0.1
            c-122.5,4-226.4,89.2-254.4,208c-42,1.9-81.3,19.4-111,49.6c-30.8,31.3-47.8,72.8-47.8,116.7c0,93.3,72.5,166.5,165.2,166.5h324.5
            L506.2,827.6c-0.2,0.3-0.6,0.4-0.8,0L405.1,624.1l-0.2-0.4c-2.1-3.8-6.1-6.1-10.5-6.1l-101.4,0c-2.7,0-5.4,0.7-7.6,2.3
            c-5,3.7-6.2,10.3-3.5,15.4l172.6,333l0.2,0.3c11.1,19.8,31.1,31.4,53.7,31.4c0.5,0,1.1,0,1.6,0c23.2-0.6,43.3-13.3,53.8-34.2
            l247.6-520.3c1.9-3.8,1.7-8.1-0.5-11.7c-2.2-3.6-6-5.7-10.2-5.7c-26,0-478.3-0.3-492.3-0.3c-25.9,0-47.7-24.4-47.7-53.4
            c0-13.6,5.2-27.3,14.1-37.4c9.1-10.3,21-15.9,33.6-15.9c18.6,0,88.1-1.9,94.8-1.9c6.5,0,11.7-5.2,12-11.6l3.2-45.2l0-0.6
            c0.1-11.3,1.4-21.7,3.9-31.1c17.4-64.4,76-110.8,142.6-113c41.5-1.4,81.3,14,111.2,42.9c31.7,30.7,46.5,63.9,46.5,104.6
            c0,15.4-0.3,28.4-1.8,41.2c-0.6,5.4,2,10.8,7.1,13.2c1.7,0.8,3.6,1.1,5.5,1.1l92.1,0c5.9,0,11-4.4,11.9-10.3
            C839.6,294.3,840.6,277.9,840.1,261.7z"
        />
        <path className="st0" d="M207.6,373.3L207.6,373.3C207.6,373.3,207.6,373.3,207.6,373.3z" />
      </g>
    </svg>
  )
}
export default LogoMobile
