import * as React from 'react'
import { graphql } from 'gatsby'

import { CheckboxField as CheckboxFieldType, CheckboxInput, FieldError } from '../../../../generated/graphql'
import useGravityForm, { ACTION_TYPES, FieldValue, CheckboxFieldValue } from '../../../hooks/useGravityForm'

export const CHECKBOX_FIELD_FIELDS = graphql`
  fragment CheckboxFieldFields on WpCheckboxField {
    id
    formId
    label
    description
    cssClass
    inputs {
      id
    }
    choices {
      text
      value
    }
  }
`

interface Props {
  field: CheckboxFieldType
  fieldErrors: FieldError[]
  invertColors?: boolean
}

const DEFAULT_VALUE: CheckboxInput[] = []

export default function CheckboxField({ field, fieldErrors, invertColors = false }: Props) {
  const { id, formId, type, label, description, inputs, choices } = field
  const checkboxInputs = choices?.map((choice, index) => ({ ...choice, id: inputs?.[index]?.id })) || []
  const htmlId = `field_${formId}_${id}`
  const { state, dispatch } = useGravityForm()
  const fieldValue = state.find((fieldValue: FieldValue) => fieldValue.id === id) as CheckboxFieldValue | undefined
  const checkboxValues = fieldValue?.checkboxValues || DEFAULT_VALUE

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value, checked } = event.target
    const otherCheckboxValues = checkboxValues.filter(
      (checkboxValue: CheckboxInput) => checkboxValue.inputId !== Number(name)
    )
    const newCheckboxValues = checked ? [...otherCheckboxValues, { inputId: Number(name), value }] : otherCheckboxValues

    dispatch({
      type: ACTION_TYPES.updateCheckboxFieldValue,
      fieldValue: {
        id,
        checkboxValues: newCheckboxValues,
      },
    })
  }

  const colors = invertColors ? 'border-primary text-black' : 'border-white text-white'

  return (
    <fieldset
      id={htmlId}
      className={`${colors} border-l-2 border-b-2 rounded-bl-2xl pl-2 mb-4 pb-4 gfield gfield-${type}`.trim()}
    >
      <legend className="block uppercase">{label}</legend>
      {checkboxInputs.map(({ id: inputId, text, value }) => (
        <div key={inputId} className="flex items-center">
          <input
            type="checkbox"
            name={String(inputId)}
            id={`input_${formId}_${id}_${inputId}`}
            value={String(value)}
            onChange={handleChange}
          />
          <label className="block text-sm ml-2" htmlFor={`input_${formId}_${id}_${inputId}`}>
            {text}
          </label>
        </div>
      ))}
      {description ? <p className="field-description">{description}</p> : null}
      {fieldErrors?.length
        ? fieldErrors.map(fieldError => (
            <p key={fieldError.id} className="error-message text-sm">
              {fieldError.message}
            </p>
          ))
        : null}
    </fieldset>
  )
}
