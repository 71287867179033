import React, { useState } from 'react'
import DefaultLink from '@/components/DefaultLink'

interface Navigation {
  id: string
  label: string
  parentId: string
  url: string
  childItems: any
}
interface MobileNavItemData {
  navItem?: {
    label: string
    childItems: {
      nodes?: Array<Navigation>
    }
  }
}

const MobileNavItem = ({ navItem }: MobileNavItemData) => {
  const [open, setOpen] = useState(false)

  const openSubItem = () => {
    setOpen(open == true ? false : true)
  }

  return (
    <li>
      <button
        onClick={() => openSubItem()}
        className="text-white uppercase font-bold py-2 inline-block tracking-widest border-b-2 border-primary hover:border-white focus:border-white"
      >
        {navItem?.label}
      </button>
      {open && (
        <ul className="text-sm mt-2 mb-2">
          {navItem?.childItems?.nodes?.map(childitem => {
            return (
              <li key={childitem.id}>
                <DefaultLink
                  className="text-white leading-6 uppercase font-bold inline-block tracking-widest border-b-2 border-primary hover:border-white focus:border-white"
                  to={childitem.url}
                >
                  {childitem.label}
                </DefaultLink>
              </li>
            )
          })}
        </ul>
      )}
    </li>
  )
}
export default MobileNavItem
