import React from 'react'

const FooterLogo = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1181.7 274.9">
      <g>
        <path
          className="st0"
          d="M260.9-0.1v105h-19.6v-105H260.9z M310,31.3c0,0,0,27.1-16.8,27.1c-12.6,0-18.1,0-18.1,0
        c-14.1,0-14.1,9.5-14.1,9.5s0-25.9,16.8-25.9h18.1C310,42,310,31.3,310,31.3z M329.6-0.1v105H310v-105H329.6z"
        />
        <path
          className="st0"
          d="M419.8,71.4h-57.3c1.8,12.7,10.4,18.7,19.2,18.9c5.9-0.1,11.6-2.8,15.4-8.4h20.4c-5.7,15.9-20,24.7-35.8,24.8
        c-20.3-0.2-38.4-14.8-38.4-40.5s18.4-40.5,38.4-40.5S420,40.4,420,66.1C420,67.9,419.9,69.7,419.8,71.4z M400.3,57.2
        c-3.8-9.9-10.6-15-18.6-15.1c-8,0.1-14.8,5.2-18.7,15.1H400.3z"
        />
        <path className="st0" d="M433,105V0h18.7v105H433z" />
        <path
          className="st0"
          d="M532.6,82.4c0,17-17.1,24.8-34,24.3c-22.1-0.7-35.2-13.8-32.3-24c1.9-6.8,11.4-11.5,30.9-8.2
        c-4.7-0.8-9.8-1.7-9.8-1.7c-11.3-2-22.4-9.7-21.6-24.5c0.6-12.4,14.9-22.6,32.1-22.6c20.4,0,33.9,13.1,32.2,23.9h-18.7
        c0-3.4-6.1-7.1-12.8-7.5c-7.6-0.5-13.9,2.3-13.9,7c0,3.4,3.4,5.5,9.8,6.6c0.2,0,18.2,3.2,18.2,3.2C524,61,532.6,68.9,532.6,82.4z
        M513.6,82.7c0-3.8-2.7-5.7-8.8-6.8c-0.1,0-3.2-0.5-6.7-1.2c-10.2-1.8-15.6,1-15.6,5.2c0,6.2,8.1,10.1,16,10.4
        C506.5,90.6,513.6,87.1,513.6,82.7z"
        />
        <path
          className="st0"
          d="M541.1,9.3c0-5.2,5.6-9.3,12.6-9.3c6.9,0,12.6,4.2,12.6,9.3c0,5.2-5.6,9.3-12.6,9.3
        C546.8,18.7,541.1,14.5,541.1,9.3z M544.4,105V27.6H563V105H544.4z"
        />
        <path
          className="st0"
          d="M598.4,27.6V105h-18.7V27.6H598.4z M648,53.4V105h-18.7V55.1c0-6.6-5.8-12.9-15.5-12.9
        c-8.9,0-15.5,6.3-15.5,12.9c0-16,5.3-27.6,17.3-29.2C628.9,24.2,648,34,648,53.4z"
        />
        <path
          className="st0"
          d="M698,91.9C671.4,91.5,660.1,77,660.1,60c0-17.9,18.2-32.4,37.3-32.4c0,0,0,0,0,0c4.1,0,8.2,0.7,12.1,1.9
        l12.2-12.2l11.9,11.9l-9,9c6.2,5.8,10.2,13.4,10.2,21.7C734.8,80.1,714,91.9,698,91.9z M734.3,106.4c1.2,14.4-17.9,26.1-36.7,26.1
        c-18.8,0-36.7-11.7-36.7-26.1c0-0.7,0-1.4,0.1-2h18.5c-0.1,0.5-0.1,1-0.1,1.6c0,6.5,9.2,10.2,18.3,10.2c9.6,0,17.5-5.2,18.1-11.7
        c0.5-6.1-3.8-12.4-17.7-12.6C724.6,91.9,733.5,96.4,734.3,106.4z M716.3,60c0-7.9-8.2-16-18.8-16c-11.2,0-18.8,8.1-18.8,16
        c0,9.4,6.7,17,18.8,17C709.1,77,716.3,68.9,716.3,60z"
        />
        <path
          className="st0"
          d="M743.8,9.3c0-5.2,5.6-9.3,12.6-9.3c6.9,0,12.6,4.2,12.6,9.3c0,5.2-5.6,9.3-12.6,9.3
        C749.4,18.7,743.8,14.5,743.8,9.3z M747,105V27.6h18.7V105H747z"
        />
        <path
          className="st0"
          d="M801,27.6V105h-18.7V27.6H801z M850.6,53.4V105H832V55.1c0-6.6-5.8-12.9-15.5-12.9c-8.9,0-15.5,6.3-15.5,12.9
        c0-16,5.3-27.6,17.3-29.2C831.6,24.2,850.6,34,850.6,53.4z"
        />
        <path
          className="st0"
          d="M299.7,164.9v77.7c0,19.5-14.6,30.1-32.7,30.1c-17.6,0-32.7-12.5-32.7-30.1v-6.5h19.6v6.5
        c0,7.4,5.7,13.7,13.1,13.7c7.1,0,12.8-5.9,13.1-13c0-0.2,0-78.4,0-78.4H299.7z"
        />
        <path
          className="st0"
          d="M379.9,212.5v57.4h-18.7v-55.5c0,16-5.1,19.8-12.3,19.8h-4.2c-4.9,0-12.5,1.5-13.4,9.5
        c-0.7,6.5,4.7,12.4,13.4,12.4c8.3,0,16.5-5.9,16.5-12.5c0,18-7.3,27.7-19.8,28.1c-18.9,0.6-28.8-13.2-28.8-25.6
        c0-15.8,13.1-25.6,29-25.6h11.7c4.7,0,8.1-2.7,7.9-6.1c-0.4-5.8-5.2-8.8-13.1-8.8c-5.2,0-10.1,3.2-11.8,7h-18.7
        c3.5-16.1,17.3-22,30.5-22C361.9,190.7,379.9,197.4,379.9,212.5z M322.6,173.5c0-5.2,5-9.3,11.2-9.3c6.2,0,11.2,4.2,11.2,9.3
        s-5,9.3-11.2,9.3C327.6,182.8,322.6,178.6,322.6,173.5z M352.8,173.5c0-5.2,5-9.3,11.2-9.3c6.2,0,11.2,4.2,11.2,9.3
        s-5,9.3-11.2,9.3C357.9,182.8,352.8,178.6,352.8,173.5z"
        />
        <path
          className="st0"
          d="M459.6,212.5v57.4h-18.7v-55.5c0,16-5.1,19.8-12.3,19.8h-4.2c-4.9,0-12.5,1.5-13.4,9.5
        c-0.7,6.5,4.7,12.4,13.4,12.4c8.3,0,16.5-5.9,16.5-12.5c0,18-7.3,27.7-19.8,28.1c-18.9,0.6-28.8-13.2-28.8-25.6
        c0-15.8,13.1-25.6,29-25.6H433c4.7,0,8.1-2.7,7.9-6.1c-0.4-5.8-5.2-8.8-13.1-8.8c-5.2,0-10.1,3.2-11.8,7h-18.7
        c3.5-16.1,17.3-22,30.5-22C441.6,190.7,459.6,197.4,459.6,212.5z M402.2,173.5c0-5.2,5-9.3,11.2-9.3c6.2,0,11.2,4.2,11.2,9.3
        s-5,9.3-11.2,9.3C407.2,182.8,402.2,178.6,402.2,173.5z M432.5,173.5c0-5.2,5-9.3,11.2-9.3c6.2,0,11.2,4.2,11.2,9.3
        s-5,9.3-11.2,9.3C437.5,182.8,432.5,178.6,432.5,173.5z"
        />
        <path
          className="st0"
          d="M513.1,192.6v14.6h-40.6c4,0,27.5,0.7,27.5,11.7v28.8c0,3.2,2.7,5.9,5.8,5.9c3,0,7.4,0,7.4,0V270h-12.8
        c-10.4,0-19-8.4-19-19c0,0,0-29,0-33.4c0-10.4-9.4-10.4-9.4-10.4H470v-14.6h11.3V165H500v27.6H513.1z"
        />
        <path
          className="st0"
          d="M598,236.4h-57.3c1.8,12.7,10.4,18.7,19.2,18.9c5.9-0.1,11.6-2.8,15.4-8.4h20.4c-5.7,15.9-20,24.7-35.8,24.8
        c-20.3-0.2-38.4-14.8-38.4-40.5c0-25.8,18.4-40.5,38.4-40.5s38.4,14.8,38.4,40.5C598.2,232.9,598.1,234.7,598,236.4z M578.5,222.2
        c-3.8-9.9-10.6-15-18.6-15.1c-8,0.1-14.8,5.2-18.7,15.1H578.5z"
        />
        <path className="st0" d="M611.2,270V165h18.7v105H611.2z" />
        <path
          className="st0"
          d="M719.7,231.2c0,25.7-18,40.4-38.4,40.5C661,271.6,643,257,643,231.2c0-25.8,18.4-40.5,38.4-40.5
        C701.3,190.6,719.7,205.5,719.7,231.2z M656,173.5c0-5.2,5-9.3,11.2-9.3c6.2,0,11.2,4.2,11.2,9.3s-5,9.3-11.2,9.3
        C661,182.8,656,178.6,656,173.5z M701,231.2c0-15.2-9.4-23.9-19.6-24.1c-10.2,0.2-19.6,8.8-19.6,24.1c0,16.2,9.7,24,19.6,24.1
        C691.3,255.2,701,247.4,701,231.2z M686.3,173.5c0-5.2,5-9.3,11.2-9.3s11.2,4.2,11.2,9.3s-5,9.3-11.2,9.3S686.3,178.6,686.3,173.5z
        "
        />
        <path
          className="st0"
          d="M768.4,192.6v14.6h-40.6c4,0,27.5,0.7,27.5,11.7v28.8c0,3.2,2.7,5.9,5.8,5.9c3,0,7.4,0,7.4,0V270h-12.8
        c-10.4,0-19-8.4-19-19c0,0,0-29,0-33.4c0-10.4-9.4-10.4-9.4-10.4h-1.9v-14.6h11.3V165h18.7v27.6H768.4z"
        />
        <path
          className="st0"
          d="M853.3,236.4h-57.3c1.8,12.7,10.4,18.7,19.2,18.9c5.9-0.1,11.6-2.8,15.4-8.4h20.4c-5.7,15.9-20,24.7-35.8,24.8
        c-20.3-0.2-38.4-14.8-38.4-40.5c0-25.8,18.4-40.5,38.4-40.5c19.9,0,38.4,14.8,38.4,40.5C853.5,232.9,853.4,234.7,853.3,236.4z
        M833.8,222.2c-3.8-9.9-10.6-15-18.6-15.1c-8,0.1-14.8,5.2-18.7,15.1H833.8z"
        />
        <path
          className="st0"
          d="M885.2,165v105h-18.7V165H885.2z M885.2,224.9c0-0.2,0-0.3,0-0.5C885.2,224.5,885.2,224.7,885.2,224.9z
        M934.8,218.6V270h-18.7v-45.1c0-9.6-3.8-18.3-15.5-18.3c-10.7,0-15.3,7.5-15.5,17.7c0.2-20.8,9.9-32.6,21.3-33.5
        C919.9,189.7,934.8,200.6,934.8,218.6z"
        />
        <path
          className="st0"
          d="M1021.1,164.9v105h-18.7v-11.6c-2.7,6.2-10,13.1-19.7,13.5c-19.1,0.7-35.6-15.3-35.6-39.6
        c0-24.4,16.8-42.8,35.6-39.5c8.4,1.5,19.7,10.7,19.7,39.5c0-13.7-7-23.8-18.4-23.8c-10.9,0-18.2,11.4-18.2,23.8s7.3,23.8,18.2,23.8
        c11.4,0,18.4-10.1,18.4-23.8v-67.2H1021.1z"
        />
        <path
          className="st0"
          d="M1101.4,212.5v57.4h-18.7v-55.5c0,16-5.1,19.8-12.3,19.8h-4.2c-4.9,0-12.5,1.5-13.4,9.5
        c-0.7,6.5,4.7,12.4,13.4,12.4c8.3,0,16.5-5.9,16.5-12.5c0,18-7.3,27.7-19.8,28.1c-18.9,0.6-28.8-13.2-28.8-25.6
        c0-15.8,13.1-25.6,29-25.6h11.7c4.7,0,8.1-2.7,7.9-6.1c-0.4-5.8-5.2-8.8-13.1-8.8c-5.2,0-10.1,3.2-11.8,7h-18.7
        c3.5-16.1,17.3-22,30.5-22C1083.4,190.6,1101.4,197.4,1101.4,212.5z"
        />
        <path
          className="st0"
          d="M1181.7,247.4c0,17-17.1,24.8-34,24.3c-22.1-0.7-35.2-13.8-32.3-24c1.9-6.8,11.4-11.5,30.9-8.2
        c-4.7-0.8-9.8-1.7-9.8-1.7c-11.3-2-22.4-9.7-21.6-24.5c0.6-12.4,14.9-22.6,32.1-22.6c20.4,0,33.9,13.1,32.2,23.9h-18.7
        c0-3.4-6.1-7.1-12.8-7.5c-7.6-0.5-13.9,2.3-13.9,7c0,3.4,3.4,5.5,9.8,6.6c0.2,0,18.2,3.2,18.2,3.2
        C1173.2,226,1181.7,233.9,1181.7,247.4z M1162.8,247.7c0-3.8-2.7-5.7-8.8-6.8c-0.1,0-3.2-0.5-6.7-1.2c-10.2-1.8-15.6,1-15.6,5.2
        c0,6.2,8.1,10.1,16,10.3C1155.7,255.6,1162.8,252.1,1162.8,247.7z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M189.3,70.9c-0.6-19.3-8.6-37.4-22.5-50.8C152.9,6.7,134.7-0.6,115.4-0.6c-0.8,0-1.6,0-2.4,0
        C79.5,0.5,51.1,23.8,43.4,56.3c-11.5,0.5-22.2,5.3-30.3,13.5C4.6,78.4,0,89.7,0,101.7c0,25.5,19.8,45.5,45.2,45.5h88.7L98,225.7
        c0,0.1-0.2,0.1-0.2,0L70.3,170l-0.1-0.1c-0.6-1-1.7-1.7-2.9-1.7H39.7c-0.7,0-1.5,0.2-2.1,0.6c-1.4,1-1.7,2.8-0.9,4.2l47.2,91l0,0.1
        c3,5.4,8.5,8.6,14.7,8.6c0.1,0,0.3,0,0.4,0c6.3-0.2,11.8-3.6,14.7-9.3l67.7-142.3c0.5-1,0.5-2.2-0.1-3.2c-0.6-1-1.6-1.6-2.8-1.6
        c-7.1,0-130.8-0.1-134.6-0.1c-7.1,0-13-6.7-13-14.6c0-3.7,1.4-7.5,3.9-10.2c2.5-2.8,5.7-4.4,9.2-4.4c5.1,0,24.1-0.5,25.9-0.5
        c1.8,0,3.2-1.4,3.3-3.2L74,71.1l0-0.2c0-3.1,0.4-5.9,1.1-8.5c4.8-17.6,20.8-30.3,39-30.9c11.4-0.4,22.2,3.8,30.4,11.7
        c8.7,8.4,12.7,17.5,12.7,28.6c0,4.2-0.1,7.8-0.5,11.3c-0.2,1.5,0.5,2.9,1.9,3.6c0.5,0.2,1,0.3,1.5,0.3l25.2,0c1.6,0,3-1.2,3.3-2.8
        C189.2,79.8,189.4,75.3,189.3,70.9z"
        />
        <path className="st0" d="M16.3,101.4L16.3,101.4C16.3,101.4,16.3,101.4,16.3,101.4z" />
      </g>
    </svg>
  )
}
export default FooterLogo
