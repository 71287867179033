import React from 'react'
import DefaultLink from '@/components/DefaultLink'

interface Languages {
  slug: string
  code: string
  name: string
}

interface Translations {
  uri: string
  language: {
    slug: string
  }
  slug: string
}

interface LangSelectData {
  currentLang: string
  languages: Array<Languages>
  translations: Array<Translations>
}

const LangSelect = ({ currentLang, languages = [], translations }: LangSelectData) => {
  const hidden = false

  if (hidden) {
    return null
  }

  return (
    <div className="border-secondary border rounded-full font-bold">
      {languages.map(item => {
        const translationLang: any = translations.find(({ language }) => language.slug.includes(`${item.slug}`))
        const setLocale: string =
          item.slug === 'fi' ? '/' : item.slug === 'en' ? '/en/' : item.slug === 'sv' ? '/sv/' : '/'
        const translationUri = translationLang?.uri || setLocale

        return (
          <DefaultLink
            key={item.code}
            className={`pb-[10px] pt-[9px] leading-4 text-tiny rounded-full ${
              item.slug == currentLang ? 'bg-secondary px-5 text-white' : 'px-3 text-secondary'
            } ${translationLang ? '' : 'test'}`}
            to={translationUri}
          >
            <span>{item.slug}</span>
          </DefaultLink>
        )
      })}
    </div>
  )
}

export default LangSelect
