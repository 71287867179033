import React from 'react'

const HeaderCloudsLarge = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" y="0px" viewBox="0 0 1400 101.2">
      <path
        className="st0"
        d="M0,0v47.3c55.1,19.6,133.5,32.5,211.8,21.9c56.3-7.6,110.8-18.3,164.6-30.7c30.8-7.1,51.8-3.6,75.1,9.1
        c30.3,16.5,63.8,32.1,105.3,39.5C715.3,115.4,815.2,100,927.7,52c34.5-14.7,57.4-17,95.7-0.8c107.6,45.3,275.8,33.3,376.5-17.8V0H0z
        "
      />
    </svg>
  )
}
export default HeaderCloudsLarge
