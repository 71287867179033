import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

interface Data {
  children: React.ReactNode
  to: string
  className?: string
  activeClassName?: string
  partiallyActive?: boolean
}

const DefaultLink = ({ children, to, className = '', activeClassName, partiallyActive }: Data) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <AniLink
        paintDrip
        to={to}
        hex="#69DBC8"
        className={`${className} transition-all duration-200`}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
      >
        {children}
      </AniLink>
    )
  }
  return (
    <a href={to} className={`${className}`}>
      {children}
    </a>
  )
}
export default DefaultLink
