import React from 'react'

const InfoBg = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500">
      <g>
        <path
          className="st0"
          d="M250,485c-62.8,0-121.8-24.4-166.2-68.8C39.4,371.8,15,312.8,15,250c0-62.8,24.4-121.8,68.8-166.2
                C128.2,39.4,187.2,15,250,15c62.8,0,121.8,24.4,166.2,68.8C460.6,128.2,485,187.2,485,250c0,62.8-24.4,121.8-68.8,166.2
                S312.8,485,250,485z"
        />
        <path
          className="st1"
          d="M250,30c29.7,0,58.5,5.8,85.6,17.3c26.2,11.1,49.7,26.9,69.9,47.2c20.2,20.2,36.1,43.7,47.2,69.9
                c11.5,27.1,17.3,55.9,17.3,85.6s-5.8,58.5-17.3,85.6c-11.1,26.2-26.9,49.7-47.2,69.9c-20.2,20.2-43.7,36.1-69.9,47.2
                C308.5,464.2,279.7,470,250,470s-58.5-5.8-85.6-17.3c-26.2-11.1-49.7-26.9-69.9-47.2c-20.2-20.2-36.1-43.7-47.2-69.9
                C35.8,308.5,30,279.7,30,250s5.8-58.5,17.3-85.6c11.1-26.2,26.9-49.7,47.2-69.9s43.7-36.1,69.9-47.2C191.5,35.8,220.3,30,250,30
                M250,0C111.9,0,0,111.9,0,250s111.9,250,250,250s250-111.9,250-250S388.1,0,250,0L250,0z"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M216.4,134.5c0-13.8,15.1-25,33.6-25s33.6,11.2,33.6,25s-15.1,25-33.6,25S216.4,148.3,216.4,134.5z M225,390.5
                V183.4h50v207.2H225z"
        />
      </g>
    </svg>
  )
}
export default InfoBg
