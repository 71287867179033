import * as React from 'react'
import { graphql } from 'gatsby'
import { useMutation, gql } from '@apollo/client'
import { GravityFormsForm as GravityFormsFormType, FormField, FieldError } from '../../../generated/graphql'
import useGravityForm from '../../hooks/useGravityForm'
import GravityFormsField from './GravityFormsField'
import { useState } from 'react'

// Create a hidden honeypot field to prevent spam
const Honeypot = () => {
  return (
    <div className="hp">
      <label htmlFor="hp">Hp</label>
      <input type="text" name="hp" id="hp" />
    </div>
  )
}

export const GRAVITY_FORM_FIELDS = graphql`
  fragment GravityFormFields on WpGravityFormsForm {
    enableHoneypot
    formId
    title
    description
    button {
      text
    }
    confirmations {
      isDefault
      message
    }
    formFields {
      nodes {
        id
        type
        ...AddressFieldFields
        ...CheckboxFieldFields
        ...DateFieldFields
        ...EmailFieldFields
        ...MultiSelectFieldFields
        ...NameFieldFields
        ...PhoneFieldFields
        ...RadioFieldFields
        ...SelectFieldFields
        ...TextFieldFields
        ...TextAreaFieldFields
        ...TimeFieldFields
        ...WebsiteFieldFields
      }
    }
  }
`

const SUBMIT_FORM = gql`
  mutation submitForm($formId: Int!, $fieldValues: [FieldValuesInput]) {
    submitGravityFormsForm(input: { formId: $formId, fieldValues: $fieldValues }) {
      entryId
      errors {
        id
        message
      }
    }
  }
`

interface Props {
  form: GravityFormsFormType
  invertColors?: boolean
}

export default function GravityFormsForm({ form, invertColors }: Props) {
  const [submitForm, { data, loading, error }] = useMutation(SUBMIT_FORM)
  const haveEntryId = Boolean(data?.submitGravityFormsForm?.entryId)
  const haveFieldErrors = Boolean(data?.submitGravityFormsForm?.errors?.length)
  const wasSuccessfullySubmitted = haveEntryId && !haveFieldErrors
  const defaultConfirmation = form?.confirmations?.find(confirmation => confirmation?.isDefault)
  const formFields = form?.formFields?.nodes || []
  const { state } = useGravityForm()
  const [hpMessage, setHpMessage] = useState<string>('')

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setHpMessage('')
    if (loading) return

    // If the form has a honeypot field, and it's filled in, show the success message
    if (event.currentTarget.hp.value) {
      return setHpMessage('Kiitos!')
    } else {
      submitForm({
        variables: {
          formId: form.formId,
          fieldValues: state,
        },
      }).catch(error => {
        console.error(error)
      })
    }
  }

  function getFieldErrors(id: number): FieldError[] {
    if (!haveFieldErrors) return []
    return data.submitGravityFormsForm.errors.filter((error: FieldError) => error.id === id)
  }

  if (wasSuccessfullySubmitted) {
    return <p>{defaultConfirmation?.message || 'Form successfully submitted - thank you.'}</p>
  }
  const colors = invertColors
    ? 'text-white bg-primary border-primary focus:bg-white hover:bg-white hover:text-primary focus:text-primary hover:border-primary focus:border-primary'
    : 'text-primary bg-white border-white focus:bg-primary hover:bg-primary hover:text-white focus:text-white hover:border-white focus:border-white'

  return (
    <form method="post" onSubmit={handleSubmit}>
      {formFields.map(field => (
        <GravityFormsField
          key={field?.id}
          field={field as FormField}
          invertColors={invertColors}
          fieldErrors={getFieldErrors(Number(field?.id))}
        />
      ))}
      <Honeypot />
      {error ? <p className="error-message">{error.message}</p> : null}
      {hpMessage ? <p className="success-message">{hpMessage}</p> : null}
      <button
        className={`${colors} leading-6 font-bold tracking-full mt-8 px-6 pt-2 pb-1 inline-block border-2 rounded-3xl uppercase text-center`}
        type="submit"
        disabled={loading}
      >
        {loading ? 'Lähetetään...' : form?.button?.text || 'Tilaa'}
      </button>
    </form>
  )
}
