import React, { useState } from 'react'
import DefaultLink from '@/components/DefaultLink'
import RoundedLink from '@/components/RoundedLink'
import Container from '@/components/Container'
import ChildMenu from '@/components/Nav/ChildMenu'
import LangSelect from '@/components/Nav/LangSelect'
import MobileNav from '@/components/Nav/MobileNav'
import { Squash as Hamburger } from 'hamburger-react'
import Logo from '@/assets/Logo'
import LogoMobile from '@/assets/LogoMobile'
import HeaderClouds from '@/assets/HeaderClouds'
import HeaderCloudsLarge from '@/assets/HeaderCloudsLarge'
import { useWindowSize } from '@/functions'

interface Languages {
  slug: string
  code: string
  name: string
}

interface Translations {
  uri: string
  language: {
    slug: string
  }
  slug: string
}

interface Navigation {
  id: string
  label: string
  parentId: string
  url: string
}

interface NavData {
  nav: {
    menuItems: {
      nodes: Array<Navigation>
    }
    navButton: any
  }
  lang: {
    currentLang: string
    languages: Array<Languages>
    translations: Array<Translations>
  }
  showhero: boolean
}

const Nav = ({ nav, lang, showhero = true }: NavData) => {
  const width = useWindowSize()

  const { currentLang } = lang
  const { languages } = lang
  const { translations } = lang

  const { menuItems } = nav || []

  const { navButton } = nav

  const subscribrButton =
    currentLang === 'fi'
      ? JSON.parse(navButton?.subscribeFi)
      : currentLang === 'en'
      ? JSON.parse(navButton?.subscribeEn)
      : JSON.parse(navButton?.subscribeFi)

  const giftcardButton =
    currentLang === 'fi'
      ? JSON.parse(navButton?.giftcardFi)
      : currentLang === 'en'
      ? JSON.parse(navButton?.giftcardEn)
      : JSON.parse(navButton?.giftcardFi)

  const [submenu, setSubmenu] = useState(0)
  const [childWidth, setChildWidth] = useState(0)
  const [currentParent, setCurrentParent] = useState()
  const [mobileMenu, setMobileMenu] = useState(false)

  const openSubMenu = (submenu: number, e?: any): void => {
    setChildWidth(e.currentTarget.offsetWidth)
    setSubmenu(submenu)
    setCurrentParent(e.target)
  }
  return (
    <>
      <header className="absolute w-full transition-none z-50">
        <div className="bg-primary">
          <div className="top-nav w-full bg-gray py-1 pt-2 relative bg-primary">
            <Container className="mx-auto flex text-white uppercase" classNameInner="flex">
              <div className="ml-auto flex">
                <LangSelect languages={languages} currentLang={currentLang} translations={translations} />
              </div>
            </Container>
          </div>
          <div className={`main-nav w-full bg-primary lg:h-30 flex items-center uppercase relative`}>
            <div id="nav" className="w-full relative">
              <Container className="mx-auto flex text-white" classNameInner="flex">
                <DefaultLink className="w-16 sm:w-20 lg:w-36 -mt-3 flex-shrink-0 main-logo" to="/">
                  {width > 1023 ? <Logo /> : <LogoMobile />}
                </DefaultLink>
                {width > 1023 && (
                  <nav className="mx-auto flex-wrap hidden lg:flex mt-10">
                    {menuItems?.nodes.map((item: any, index: number) => {
                      if (item.parentId === null) {
                        if (item.childItems.nodes.length > 0) {
                          return (
                            <div key={item.id + index}>
                              <button
                                onClick={e => openSubMenu(index + 1, e)}
                                className="leading-4 text-gray uppercase tracking-widest font-bold py-2 ml-6 border-b-2 border-primary hover:border-white focus:border-white"
                              >
                                {item.label}
                              </button>
                              {submenu === index + 1 && (
                                <ChildMenu
                                  onClickOutside={() => {
                                    setSubmenu(0)
                                  }}
                                  childItems={item?.childItems?.nodes}
                                  parent={currentParent}
                                  eWidth={childWidth}
                                />
                              )}
                            </div>
                          )
                        } else {
                          return (
                            <div key={item.id + index}>
                              <DefaultLink
                                className="inline-block leading-4 text-gray uppercase tracking-widest py-2 font-bold ml-6 border-b-2 border-primary hover:border-white focus:border-white"
                                to={item.url}
                              >
                                {item.label}
                              </DefaultLink>
                            </div>
                          )
                        }
                      } else {
                        return null
                      }
                    })}
                  </nav>
                )}
                <div className="hidden lg:block mt-8 text-right flex-[0]">
                  {subscribrButton?.url && (
                    <RoundedLink
                      invert={true}
                      to={subscribrButton?.url}
                      targetBlank={subscribrButton?.blank}
                      className="mt-1"
                    >
                      {subscribrButton?.anchor}
                    </RoundedLink>
                  )}
                  {giftcardButton?.url && (
                    <RoundedLink
                      invert={true}
                      to={giftcardButton?.url}
                      targetBlank={giftcardButton?.blank}
                      className="mt-1 ml-2"
                    >
                      {giftcardButton?.anchor}
                    </RoundedLink>
                  )}
                </div>
                <div className="items-right ml-auto flex-wrap flex lg:hidden text-gray">
                  <Hamburger toggled={mobileMenu} toggle={() => setMobileMenu(mobileMenu === true ? false : true)} />
                </div>
              </Container>
            </div>
          </div>
          <MobileNav
            nav={menuItems}
            open={mobileMenu}
            width={width}
            subscribrButton={subscribrButton}
            giftcardButton={giftcardButton}
          />
        </div>
        <div className="realative overflow-visible w-full">
          <div className={` ${showhero ? 'absolute' : 'relative'} z-10 left-0 w-full text-primary`}>
            {width > 1800 ? <HeaderCloudsLarge /> : <HeaderClouds />}
          </div>
        </div>
      </header>
      <div className="h-[90px] sm:h-[110px] lg:h-[170px] bg-filterprimary"></div>
    </>
  )
}
export default Nav
