import React from 'react'

const FooterClouds = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1400 122.9">
      <path
        className="st0"
        d="M1400,122.9V61.4c-4.5-3-8.8-6.4-12.6-10.1c-26.4-25.7-58.2-38.5-94.1-42.9c-30.3-2.6-39.9-1.7-64,1.8
        c-39.6,9.4-74.6,27.4-106.3,53.3c-28.4,23.2-61.4,19.7-93,6.7c-43.8-18-88-27.7-132.8-6.9c-22,10.2-40.4,23.6-55.4,39.7
        c-73.7-59.4-182.5-90.6-280.7-32.9c-23.8,14-44.6,30.8-62.5,50.1c-19.8-3.5-40.5-3.5-61.4,0.8c-16.8,3.4-25-3.3-34.7-15
        C361,56.5,309.2,21.5,246.1,6.3C143.7-18.3,54.9,32.5,0,102.1v20.8H1400z"
      />
    </svg>
  )
}
export default FooterClouds
