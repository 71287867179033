import React from 'react'
import Instagram from '@/assets/Instagram'
import Facebook from '@/assets/Facebook'
import Linkedin from '@/assets/Linkedin'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
interface SocialMediaData {
  instagram?: any
  heading?: string
}

const SocialMedia = ({ instagram, heading = 'SEURAA MEITÄ SOMESSA' }: SocialMediaData) => {
  const IG: any = instagram?.nodes || []

  IG.map((some: any) => {
    some.someType = 'IG'
  })

  const AllSome: any = [...IG]

  const { t } = useTranslation()

  const SocialLinks: any = [
    { link: 'https://www.facebook.com/helsinginjaatelotehdas', name: 'Facebook', someid: 1 },
    { link: 'https://www.instagram.com/helsinginjaatelotehdas/', name: 'Instagram', someid: 2 },
    { link: 'https://fi.linkedin.com/company/helsinginjaatelotehdas', name: 'Linkedin', someid: 3 },
  ]

  return (
    <div className="my-20 md:my-28 text-center">
      <h2 className="text-3xl uppercase text-primary">{t(heading)}</h2>
      <div className="flex justify-center items-center flex-wrap">
        {SocialLinks.map((item: any) => {
          return (
            <div
              className="flex justify-center items-center flex-col text-center m-4 text-primary font-bold uppercase"
              key={item.name}
            >
              <p>{item.name}</p>
              <a className="text-primary some-icon mt-3" target="_blank" href={item.link} rel="noreferrer">
                {item.someid == 1 && <Facebook />}
                {item.someid == 2 && <Instagram />}
                {item.someid == 3 && <Linkedin />}
              </a>
            </div>
          )
        })}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mt-10">
        {AllSome.map((some: any, index: number) => {
          if (some.someType === 'IG') {
            const image: any = getImage(some?.localFile)
            return (
              <a
                className={`relative group overflow-hidden ${index > 3 ? 'hidden lg:block' : ''}`}
                key={some.id}
                href={some.permalink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="overflow-hidden bg-center bg-no-repeat bg-cover">
                  <GatsbyImage className="" image={image} alt={some.caption} />
                  <div className="absolute wordbreak w-full h-full text-white opacity-0  hover:opacity-75 group-focus:opacity-75 transition-all bottom-0">
                    <div className="flex w-full h-full overflow-hidden flex-col">
                      <div className="flex pb-0 some-icon-feed justify-end items-end mt-auto mr-2 mb-2">
                        <Instagram />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            )
          }
        })}
      </div>
    </div>
  )
}
export default SocialMedia
