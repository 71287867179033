import React from 'react'
import { motion } from 'framer-motion'

const spinme = {
  rest: { rotateY: 0, duration: 1.0, type: 'tween' },
  hover: { rotateY: 180, duration: 1.0, type: 'tween' },
}

const Logo = () => {
  return (
    <motion.div initial="rest" whileHover="hover" animate="rest">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000">
        <g>
          <motion.path
            variants={spinme}
            className="st0"
            d="M647.2,394.5c-1-31.3-14-60.5-36.5-82.2c-22.5-21.7-52-33.6-83.2-33.6c-1.3,0-2.6,0-3.9,0.1
              c-54.2,1.8-100.3,39.5-112.7,92.1c-18.6,0.8-36,8.6-49.1,21.9c-13.6,13.9-21.2,32.2-21.2,51.7c0,41.3,32.1,73.7,73.1,73.7h143.7
              l-58.3,127.3L454.6,555l-0.1-0.2c-0.9-1.7-2.7-2.7-4.6-2.7h-45.1c-1.9,0-3.6,1-4.6,2.6c-0.9,1.6-1,3.6-0.1,5.2l76.4,147.4l0.1,0.1
              c4.9,8.7,13.8,13.9,23.8,13.9c0.2,0,0.5,0,0.7,0c10.3-0.2,19.2-5.9,23.8-15.1l109.6-230.4c0.8-1.7,0.8-3.6-0.2-5.2
              c-1-1.6-2.7-2.5-4.5-2.5c-11.5,0-65.2,0-116.4-0.1c-48.8,0-95.4-0.1-101.5-0.1c-11.4,0-21.1-10.8-21.1-23.6c0-6,2.3-12.1,6.3-16.6
              c4-4.6,9.3-7.1,14.9-7.1c8.2,0,19-0.3,27.6-0.5c6.1-0.2,11.4-0.3,14.4-0.3c2.9,0,5.2-2.3,5.3-5.1l1.4-20l0-0.3
              c0-5,0.6-9.6,1.7-13.8c7.7-28.5,33.7-49.1,63.1-50c18.4-0.6,36,6.2,49.2,19c14.1,13.6,20.6,28.3,20.6,46.3c0,6.8-0.1,12.6-0.8,18.3
              c-0.2,1.7,0.3,3.3,1.4,4.5c1,1.2,2.5,1.8,4,1.8h40.9c2.6,0,4.9-2,5.3-4.5C647,408.9,647.5,401.6,647.2,394.5z"
          />
          <g>
            <path
              className="st0"
              d="M1000,437.3c0-9.1,0-18.2,0-27.4c-4.9-27.4-16.7-50.8-38.2-69.1c-17-14.5-24.2-33.2-22.8-55.5
                  c0.9-13.7,0.1-27.3-4.2-40.5c-11.5-35.5-35.9-57.6-70.8-69.5c-19.6-6.7-32.8-20.4-39.9-39.9c-4.8-13.2-10.2-26-19.3-36.9
                  c-22.1-26.3-50.4-39.1-84.8-38.2c-8.1,0.2-16.3,1.1-24.3-0.7c-14.3-3.3-26.6-10-36.4-21.2c-13-15-28.2-26.7-47.3-33.1
                  c-28.8-9.6-56.2-6.1-83.1,7.1c-19.3,9.5-38.8,9.1-58.3,0.1c-7.3-3.4-14.6-7-22.6-9c-41.4-10.1-76.6,0.7-105,32.2
                  c-16.2,17.9-35.5,26.7-59.6,24.8c-13.1-1-26,0.3-38.5,4.3c-35.2,11.2-57.3,35.4-69.1,69.8c-7,20.4-20.9,33.9-41.1,41.1
                  c-13.2,4.7-25.9,10.4-36.7,19.6c-25.9,22.1-38.3,50.2-37.4,84.2c0.2,8.1,1.1,16.3-0.8,24.3c-3.3,14.3-10,26.6-21.2,36.4
                  c-15,13-26.7,28.2-33.1,47.3c-9.5,28.4-6.3,55.7,6.8,82.2c9.9,20,9.6,40,0,60.1c-3.3,7-6.5,14.3-8.4,21.7
                  c-10.8,42.6,1.7,78.1,34.2,107c16.3,14.6,24,32.7,22.7,54.6c-0.8,14-0.1,28,4.3,41.5c10.4,31.9,30.9,54.9,62.7,65.9
                  c26,8.9,43,25.4,51.3,51.6c5.3,16.8,15.6,30.5,28.9,42c23,19.8,49.9,26.6,79.7,24.7c20.7-1.3,38,6.3,52.2,21.4
                  c6,6.4,12,12.8,18.9,18.3c14.6,11.7,31.9,16.5,49.3,21.2c4.6,0,9.1,0,13.7,0c3.9-2.1,8.1-0.5,12.1-1c13.7,0.8,26.1-3.8,38.2-9.4
                  c17.4-8.1,35.2-9.2,52.7-1.2c12.1,5.6,24.5,9.8,37.7,11.6c9.8,0,19.5,0,29.3,0c29.2-1.6,51.5-15.7,69.7-37.6
                  c3.3-4,7.5-7.1,11.8-10.1c13.9-9.7,29.6-10.8,45.8-10.3c47.1,1.5,89-25.6,105.1-70.7c2.6-7.3,5.7-14.5,10.1-21.1
                  c9.5-14.5,24.2-21.1,39.5-27.1c25.6-10,44.5-27.2,57.1-51.9c9.8-19.3,11.8-39.7,11.1-60.7c-0.6-19.4,6.5-35.5,20.2-49
                  c6-5.9,12.6-11.3,17.9-18c12.4-15.8,18.9-33.7,20-53.7c0-8.5,0-16.9,0-25.4c-1.5-14.3-6.3-27.6-12.1-40.7
                  c-6.7-15.3-6.8-31.2-0.4-46.6C992.9,464.1,998.4,451.4,1000,437.3z M500,811.3c-171.9,0-311.3-139.4-311.3-311.3
                  S328.1,188.7,500,188.7S811.3,328.1,811.3,500S671.9,811.3,500,811.3z"
            />
          </g>
          <g>
            <path
              className="st1"
              d="M142.1,590.6l-48.2,10c-12.1,2.5-20.5-5.1-22.9-16.4c-2.3-10.9,3.5-21.9,14.4-24.1l4.1-0.8l2.5,12.1l-4.1,0.8
                  c-4.6,1-7.7,5.3-6.8,9.9c0.9,4.4,5.3,7.2,9.7,6.5c0.1,0,48.6-10.1,48.6-10.1L142.1,590.6z"
            />
            <path
              className="st1"
              d="M116.2,706.4l-5.3-11.7l14.2-13l-12.1-26.8l-19.1,2.1l-5.3-11.7l69.3-7.9l9.9,21.9L116.2,706.4z M133.2,674.1
                  l26.5-24.5l-35.8,3.9L133.2,674.1z M167.2,628.4c3-1.3,6.7,0.5,8.3,4c1.6,3.6,0.5,7.6-2.5,8.9c-3,1.3-6.7-0.5-8.3-4
                  C163.2,633.7,164.3,629.7,167.2,628.4z M175.1,645.8c3-1.3,6.7,0.5,8.3,4c1.6,3.6,0.5,7.6-2.5,8.9c-3,1.3-6.7-0.5-8.3-4
                  C171,651.1,172.1,647.2,175.1,645.8z"
            />
            <path
              className="st1"
              d="M179.7,794.5l-8-10l10.5-16.1l-18.4-23l-17.9,6.7l-8-10l65.2-24.7L218,736L179.7,794.5z M188.3,759l19.7-30.2
                  l-33.7,12.7L188.3,759z M209.9,706.3c2.6-2,6.6-1.2,9,1.9c2.4,3.1,2.4,7.2-0.2,9.2c-2.6,2-6.6,1.2-9-1.9
                  C207.3,712.4,207.4,708.3,209.9,706.3z M221.9,721.2c2.6-2,6.6-1.2,9,1.9c2.4,3.1,2.4,7.2-0.2,9.2c-2.6,2-6.6,1.2-9-1.9
                  C219.2,727.4,219.3,723.3,221.9,721.2z"
            />
            <path
              className="st1"
              d="M243.2,763.8l41.5,34.4l-7,8.5l-9.4-7.8c0,0-6.7-5.5-12.2,1.1l-29.9,36l-9.4-7.8l29.3-35.3
                  c6.1-7.3,19.9,4.1,19.9,4.1l-29.8-24.7L243.2,763.8z"
            />
            <path
              className="st1"
              d="M315.4,829.2l-8.1,15.5l29.2,15.2l-4.8,9.2l-29.2-15.2l-8.1,15.5l32.4,16.8l-4.9,9.3l-43.4-22.5l30.6-58.9
                  l43.4,22.5l-4.9,9.3L315.4,829.2z"
            />
            <path className="st1" d="M413,914.2l-2.7,10L365.2,912l17.5-64.1l12,3.3l-14.7,54L413,914.2z" />
            <path
              className="st1"
              d="M515.5,900.5c-0.8,19.3-14.8,34.5-34.1,33.7c-19.3-0.8-31.4-17-30.6-36.3c0.8-19.3,14.2-34.5,33.5-33.7
                  C503.6,864.9,516.3,881.1,515.5,900.5z M503.2,900c0.5-13-6.3-24.9-19.3-25.4s-20.2,10.8-20.7,23.8c-0.5,13,5.8,24.9,18.7,25.4
                  C494.9,924.3,502.6,912.9,503.2,900z M468.4,851c0.1-3.3,3.4-5.8,7.3-5.6c3.9,0.2,7,2.9,6.9,6.2s-3.4,5.8-7.3,5.6
                  C471.4,857.1,468.3,854.3,468.4,851z M487.6,851.8c0.1-3.3,3.4-5.8,7.3-5.6c3.9,0.2,7,2.9,6.9,6.2c-0.1,3.3-3.4,5.8-7.3,5.6
                  C490.5,857.9,487.4,855.1,487.6,851.8z"
            />
            <path
              className="st1"
              d="M541.4,865l53-9.8l2,10.8l-12,2.2c0,0-8.5,1.6-6.9,10.1l8.5,45.9l-12.1,2.2l-8.4-45.1
                  c-1.7-9.4,15.9-12.7,15.9-12.7l-38.1,7.1L541.4,865z"
            />
            <path
              className="st1"
              d="M637.9,851.2l6.8,16.1l30.3-12.8l4.1,9.6l-30.3,12.8l6.8,16.1l33.6-14.2l4.1,9.7l-45,19l-25.8-61.2l45-19
                  l4.1,9.7L637.9,851.2z"
            />
            <path
              className="st1"
              d="M705.7,804.2l39.3,53.6l-10,7.3l-39.3-53.6L705.7,804.2z M742.5,801.8c0,0,10.2,13.8,1.6,20.1
                  c-6.4,4.7-9.2,6.8-9.2,6.8c-7.2,5.3-3.7,10.1-3.7,10.1s-9.7-13.2-1.1-19.5l9.2-6.8C746.5,807.3,742.5,801.8,742.5,801.8z
                  M740.8,778.5l39.3,53.6l-10,7.3l-39.3-53.6L740.8,778.5z"
            />
            <path
              className="st1"
              d="M827.8,732.6c7.6,6.4,12.1,14.9,13.1,23.2c0.8,7.1-0.9,14-5.4,19.4L817,797.2l-50.9-42.6l18.5-22.1
                  c4.5-5.4,11-8.3,18.1-8.7C811.1,723.3,820.2,726.2,827.8,732.6z M819.9,742c-10.5-8.8-21.9-9.2-29.2-0.4l0,0l-8.7,10.3l34.8,29.1
                  c0,0,8.8-10.6,8.9-10.7C832.8,761.6,830.4,750.7,819.9,742z"
            />
            <path
              className="st1"
              d="M905.7,660l-5.7,11.5l-19.1-2.7l-13.1,26.4l13.7,13.4l-5.7,11.5l-50-48.5l10.6-21.5L905.7,660z M869.9,667.3
                  l-35.7-5l25.7,25.2L869.9,667.3z"
            />
            <path
              className="st1"
              d="M913.9,562.4c12.3,2.8,16.4,18,13.1,31.1c-4.3,17.4-18,23.6-28.6,21.2l2.7-12c4.9,1.1,12.5-1.2,15.6-11.3
                  c2.3-7.4,0.3-15.4-5.6-16.8c-4.6-1.1-8,2.4-10.3,6.9c-0.2,0.3-5.8,12.1-5.8,12.1c-4.8,10.1-11.9,16-23.3,13.5
                  c-10.9-2.5-16.2-14.8-13-29.1c3.8-16.9,15.3-23.6,26.6-21.1l-2.7,12.1c-5.3-1.2-11.7,2.5-13.7,11.3c-1.6,7.1,0.9,13.6,5.5,14.7
                  c4.2,1,7.1-2.1,9.1-6.1c0.2-0.3,5.8-12.1,5.8-12.1C894.6,566.2,902.7,559.9,913.9,562.4z"
            />
          </g>
          <g>
            <path
              className="st1"
              d="M178.9,215.3l48.6,45.7l-8.6,9.2l-48.6-45.7L178.9,215.3z M209.1,183.1l48.6,45.7l-8.6,9.2l-48.6-45.7
                  L209.1,183.1z M215,205.9c0,0,12.5,11.8,5.2,19.7c-5.5,5.9-8,8.5-8,8.5c-6.2,6.6-1.8,10.7-1.8,10.7s-12-11.3-4.6-19.1l8-8.5
                  C219.9,210.6,215,205.9,215,205.9z"
            />
            <path
              className="st1"
              d="M260.1,156.1l9.8,14.6l27.7-18.6l5.8,8.7l-27.7,18.6l9.8,14.6l30.7-20.6l5.9,8.8l-41.2,27.7l-37.2-55.3
                  l41.2-27.7l5.9,8.8L260.1,156.1z"
            />
            <path className="st1" d="M387.3,141.8l3.9,9.7l-44,17.8l-25-61.8l11.7-4.7l21.1,52.1L387.3,141.8z" />
            <path
              className="st1"
              d="M464.1,118c2.2,12.5-10.5,22.2-24,24.2c-18,2.7-29.1-7.5-31-18.3l12.4-2.2c0.9,4.9,6,11.1,16.7,10.1
                  c7.9-0.8,14.6-5.7,13.6-11.7c-0.8-4.7-5.4-6.5-10.5-6.9c-0.4,0-13.6-0.7-13.6-0.7c-11.3-0.6-19.7-4.8-21.7-16.4
                  c-1.9-11.1,7.5-20.8,22.2-23.3c17.4-3,28.1,5,30.1,16.4l-12.4,2.2c-0.9-5.3-6.9-9.9-15.9-8.3c-7.3,1.3-12.4,6.1-11.6,10.8
                  c0.7,4.3,4.8,5.8,9.2,6.1c0.3,0,13.6,0.7,13.6,0.7C453,101.7,462.1,106.7,464.1,118z"
            />
            <path className="st1" d="M495.7,135l-0.1-66.7l12.6,0l0.1,66.7L495.7,135z" />
            <path
              className="st1"
              d="M611.3,81.4L599,146.9l-24.4-4.6l-11.9-66.5l-11.7,62l-12.4-2.3L550.9,70l24.4,4.6l11.9,66.5l11.7-62
                  L611.3,81.4z"
            />
            <path
              className="st1"
              d="M683.1,154.1c-1.9,4.1-10.9,23.1-31.3,15.5c-17.9-6.6-21.7-26.8-13.2-44.9c8.5-18.1,27.1-26.1,43-18.6
                  c17.1,8.1,21.9,23.5,18.4,35.3l-11.3-5.3c1.5-7.2-1.5-16.1-11.3-20.3c-11-4.8-22,3-27.4,14.3c-5.3,11.3-4.9,23.5,6.4,30.3
                  C664.3,165,676.6,165.2,683.1,154.1z M672.1,177.3l10.9-23.2l-19.4-9.2l4.5-9.5l29.1,13.7c-0.3,0.6-15.4,32.7-15.4,32.7
                  L672.1,177.3z"
            />
            <path className="st1" d="M705.9,198.8l39.2-53.9l10.2,7.4l-39.2,53.9L705.9,198.8z" />
            <path
              className="st1"
              d="M830.9,223.6l-48.6,45.6l-17-18.1l29.5-60.7l-46,43.2l-8.6-9.2l48.6-45.6l17,18.1l-29.5,60.7l46-43.2
                  L830.9,223.6z"
            />
          </g>
          <path className="st2" d="M367.2,443.9L367.2,443.9C367.2,443.9,367.2,443.9,367.2,443.9z" />
          <path
            className="st1"
            d="M94.1,429H83.3v-29.6l0.1-4.9l0.2-5.3c-1.8,1.8-3,3-3.7,3.5l-5.9,4.7l-5.2-6.5L85.2,378h8.9V429z"
          />
          <path
            className="st1"
            d="M152.4,399.8c0,10.1-2.1,17.6-6.4,22.5c-4.2,5-10.7,7.4-19.2,7.4c-3,0-5.3-0.2-6.9-0.5v-8.7
              c2,0.5,4,0.7,6.1,0.7c3.6,0,6.6-0.5,8.9-1.6s4.1-2.7,5.3-5c1.2-2.3,1.9-5.4,2.1-9.4H142c-1.4,2.2-2.9,3.7-4.7,4.6
              c-1.8,0.9-4,1.3-6.6,1.3c-4.4,0-8-1.4-10.5-4.3c-2.6-2.9-3.8-6.8-3.8-11.9c0-5.5,1.6-9.8,4.7-13c3.1-3.2,7.3-4.8,12.7-4.8
              c3.8,0,7.1,0.9,9.9,2.7c2.8,1.8,5,4.3,6.5,7.7S152.4,395.1,152.4,399.8z M133.9,386c-2.2,0-4,0.8-5.2,2.3c-1.3,1.5-1.9,3.8-1.9,6.6
              c0,2.5,0.6,4.4,1.7,5.9c1.1,1.4,2.9,2.2,5.2,2.2c2.2,0,4.1-0.7,5.6-2.1c1.6-1.4,2.3-3.1,2.3-4.9c0-2.8-0.7-5.1-2.2-7
              C138,387,136.2,386,133.9,386z"
          />
          <path
            className="st1"
            d="M888.3,429.4h-35.7v-7.5l12.8-13c3.8-3.9,6.3-6.6,7.4-8.1c1.2-1.5,2-2.9,2.5-4.2c0.5-1.3,0.8-2.6,0.8-4
              c0-2-0.6-3.6-1.7-4.6c-1.1-1-2.6-1.5-4.5-1.5c-2,0-3.9,0.5-5.8,1.4c-1.9,0.9-3.8,2.2-5.8,3.9l-5.9-7c2.5-2.1,4.6-3.7,6.3-4.5
              s3.5-1.6,5.4-2s4.1-0.7,6.6-0.7c3.2,0,6,0.6,8.5,1.7s4.3,2.8,5.7,4.9s2,4.5,2,7.2c0,2.4-0.4,4.6-1.2,6.6c-0.8,2.1-2.1,4.2-3.8,6.3
              s-4.8,5.3-9.2,9.3l-6.6,6.2v0.5h22.3V429.4z"
          />
          <path
            className="st1"
            d="M935.6,429.4h-35.7v-7.5l12.8-13c3.8-3.9,6.3-6.6,7.4-8.1c1.2-1.5,2-2.9,2.5-4.2c0.5-1.3,0.8-2.6,0.8-4
              c0-2-0.6-3.6-1.7-4.6s-2.6-1.5-4.5-1.5c-2,0-3.9,0.5-5.8,1.4c-1.9,0.9-3.8,2.2-5.8,3.9l-5.9-7c2.5-2.1,4.6-3.7,6.3-4.5
              c1.7-0.9,3.5-1.6,5.4-2s4.1-0.7,6.6-0.7c3.2,0,6,0.6,8.5,1.7s4.3,2.8,5.7,4.9s2,4.5,2,7.2c0,2.4-0.4,4.6-1.2,6.6s-2.1,4.2-3.8,6.3
              c-1.7,2.2-4.8,5.3-9.2,9.3l-6.6,6.2v0.5h22.3V429.4z"
          />
        </g>
      </svg>
    </motion.div>
  )
}
export default Logo
