import * as React from 'react'

import { FormField, FieldError } from '../../../generated/graphql'
import AddressField from './GravityFormsFields/AddressField'
import CheckboxField from './GravityFormsFields/CheckboxField'
import DateField from './GravityFormsFields/DateField'
import EmailField from './GravityFormsFields/EmailField'
import MultiSelectField from './GravityFormsFields/MultiSelectField'
import NameField from './GravityFormsFields/NameField'
import PhoneField from './GravityFormsFields/PhoneField'
import RadioField from './GravityFormsFields/RadioField'
import SelectField from './GravityFormsFields/SelectField'
import TextField from './GravityFormsFields/TextField'
import TextAreaField from './GravityFormsFields/TextAreaField'
import TimeField from './GravityFormsFields/TimeField'
import WebsiteField from './GravityFormsFields/WebsiteField'

interface Props {
  field: FormField
  fieldErrors: FieldError[]
  invertColors?: boolean
}

export default function GravityFormsField({ field, fieldErrors, invertColors }: Props) {
  switch (field.type) {
    case 'address':
      return <AddressField invertColors={invertColors} field={field} fieldErrors={fieldErrors} />
    case 'checkbox':
      return <CheckboxField invertColors={invertColors} field={field} fieldErrors={fieldErrors} />
    case 'date':
      return <DateField invertColors={invertColors} field={field} fieldErrors={fieldErrors} />
    case 'email':
      return <EmailField invertColors={invertColors} field={field} fieldErrors={fieldErrors} />
    case 'multiselect':
      return <MultiSelectField invertColors={invertColors} field={field} fieldErrors={fieldErrors} />
    case 'name':
      return <NameField invertColors={invertColors} field={field} fieldErrors={fieldErrors} />
    case 'phone':
      return <PhoneField invertColors={invertColors} field={field} fieldErrors={fieldErrors} />
    case 'radio':
      return <RadioField invertColors={invertColors} field={field} fieldErrors={fieldErrors} />
    case 'select':
      return <SelectField invertColors={invertColors} field={field} fieldErrors={fieldErrors} />
    case 'text':
      return <TextField invertColors={invertColors} field={field} fieldErrors={fieldErrors} />
    case 'textarea':
      return <TextAreaField invertColors={invertColors} field={field} fieldErrors={fieldErrors} />
    case 'time':
      return <TimeField invertColors={invertColors} field={field} fieldErrors={fieldErrors} />
    case 'website':
      return <WebsiteField invertColors={invertColors} field={field} fieldErrors={fieldErrors} />
    default:
      return <p>{`Field type not supported: ${field.type}.`}</p>
  }
}
