import { useState, useEffect } from 'react'
/**
 * All Helper functions are listed here
 */

/**
 *
 * @param string Content
 * @param number Number of words to show
 * @returns
 */
export const trimWords = (string: any = '', number = 1) => {
  const trimmedString = string?.split(' ').slice(0, number).join(' ')

  return trimmedString
}

/**
 * Check is browser
 * @return winwosize
 */
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(0)
  function handleResize() {
    setWindowSize(window.innerWidth)
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      handleResize()
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])
  return windowSize
}
